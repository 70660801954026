import React from "react"

class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index:
        typeof props.selectedIndex !== "undefined" ? props.selectedIndex : 0,
    }
    this.nodes = []
  }

  componentWillReceiveProps(props) {
    if (
      typeof props.selectedIndex !== "undefined" &&
      this.state.index !== props.selectedIndex
    ) {
      this.toggle(props.selectedIndex)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.children[0].props["data-header"] !==
      this.props.children[0].props["data-header"]
    ) {
      if (this.nodes[this.state.index] && this.nodes[this.state.index].ref) {
        const content = this.nodes[this.state.index].ref.children[1]

        content.style.height = `${content.children[0].offsetHeight}px` // Set fixed height before collapse of current open item
      }
    }
  }

  componentDidMount() {
    this.setState({ index: 0 })
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  toggle(index, click) {
    clearTimeout(this.timeout)

    if (click) {
      if (this.props.onChange)
        this.props.onChange(
          index,
          this.state.index !== index,
          this.state.index !== index ? index : -1
        )
      if (!this.props.changeOnClick) return
    }
    if (this.state.index > -1 && this.nodes[this.state.index].ref) {
      const content = this.nodes[this.state.index].ref.children[1]
        ? this.nodes[this.state.index].ref.children[1]
        : ""
      if (!content.children) return
      content.style.height = `${content.children[0].offsetHeight}px` // Set fixed height before collapse of current open item
    }

    if (this.state.index === index || index === -1) {
      setTimeout(() => {
        this.setState({ index: -1 })
      }, 50)
    } else {
      setTimeout(() => {
        this.setState({ index })
        this.timeout = setTimeout(() => {
          if (this.nodes[index].ref)
            this.nodes[index].ref.children[1].style.height = "auto" // Set auto height after expand
        }, this.props.transitionDuration)
      }, 50)
    }
  }

  getHeight(index) {
    if (index === this.state.index) {
      if (this.nodes.length > index) {
        if (
          !this.nodes[index] ||
          !this.nodes[index].ref ||
          !this.nodes[index].ref.children[1] ||
          !this.nodes[index].ref.children[1].children[0]
        ) {
          return "auto"
        } else {
          return this.nodes[index].ref.children[1].children[0].offsetHeight
        }
      }
    }
    return 0
  }

  render() {
    const style = {
      overflow: "hidden",
      transition: `height ${this.props.transitionDuration}ms ${this.props.transitionTimingFunction}`,
    }
    const nodes = React.Children.map(this.props.children, (child, index) => (
      <div
        key={index}
        ref={div => {
          this.nodes[index] = { ref: div }
        }}
        className={this.state.index === index ? this.props.openClassName : ""}
      >
        <div
          className="accordion__button"
          onClick={() => this.toggle(index, true)}
        >
          <h4>{child.props["data-header"]}</h4>
          <span
            className={
              `faqs__chevron ${index} ${this.state.index}` +
              (this.state.index === index
                ? " faqs__chevron--open"
                : " faqs__chevron--closed")
            }
          >
            <svg width="16" height="10" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fillRule="evenodd">
                <path stroke="#E6EAEA" d="M-723.5-24.5h769v59h-769z" />
                <path
                  d="M15.84 1.043L15.04.27a.515.515 0 00-.738 0l-6.3 6.077L1.698.27a.515.515 0 00-.737 0l-.802.773A.48.48 0 000 1.4a.48.48 0 00.16.356L7.631 8.96a.516.516 0 00.738 0l7.471-7.206a.48.48 0 00.16-.356.481.481 0 00-.16-.356z"
                  fill="#EE1944"
                  fillRule="nonzero"
                />
              </g>
            </svg>
          </span>
        </div>
        <div
          className="accordion__content"
          style={{ ...style, height: this.getHeight(index) }}
        >
          {child}
        </div>
      </div>
    ))
    return <div className={this.props.className}>{nodes}</div>
  }
}

Accordion.defaultProps = {
  transitionDuration: 500,
  transitionTimingFunction: "ease",
  openClassName: "open",
  changeOnClick: true,
}

export default Accordion
