import React, { Component } from "react"
import { v4 } from "uuid"
import Layout from "../components/global/layout"
import Accordion from "../components/global/accordion"
import SEO from "../components/global/seo"
import removeStopWords from "../utils/remove-stop-words"

/* Need to move the below out */
const data = [
  {
    category: "Intensive Moisturiser",
    faqs: [
      {
        uuid: v4(),
        faqTitle: "Does Intensive Moisturiser contain a sun protection factor?",
        faqAnswer: (
          <>
            <p>Yes, Intensive Moisturiser contains SPF 10.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "The packaging for Intensive Moisturiser has changed - is the formula still the same?",
        faqAnswer: (
          <>
            <p>
              Yes, although the packaging has been updated, the same trusted
              formulas remain.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Can I use Intensive Moisturiser overnight?",
        faqAnswer: (
          <>
            <p>
              Absolutely. Intensive Moisturiser is made up of a blend of
              moisturisers that will intensely rehydrate your lips while you
              sleep.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "Does this product contain any ingredients derived from animals?",
        faqAnswer: (
          <>
            <p>Yes, Beeswax.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Do you sell this product online? ",
        faqAnswer: (
          <>
            <p>
              Intensive Moisturiser is available to buy online through key
              retailers. Please click on the where to buy link on each product
              page.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain perfume?",
        faqAnswer: (
          <>
            <p>No, only flavourings.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain any parabens?",
        faqAnswer: (
          <>
            <p>No.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Is your packaging recyclable?",
        faqAnswer: (
          <>
            <p>
              The backing card used in our packaging is fully recyclable,
              however the applicator is not yet recyclable.
            </p>
          </>
        ),
      },
    ],
  },
  {
    category: "Intensive Moisturiser Cherry",
    faqs: [
      {
        uuid: v4(),
        faqTitle:
          "Does Intensive Moisturiser Cherry contain a sun protection factor?",
        faqAnswer: (
          <>
            <p>Yes, Intensive Moisturiser Cherry contains SPF 15.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "The packaging for Intensive Moisturiser Cherry has changed - is the formula still the same?",
        faqAnswer: (
          <>
            <p>
              Yes, although the packaging has been updated, the same trusted
              formulas remain.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "Does this product contain any ingredients derived from animals?",
        faqAnswer: (
          <>
            <p>Yes, Beeswax and Lanolin Alcohol</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Do you sell this product online?",
        faqAnswer: (
          <>
            <p>
              Intensive Moisturiser Cherry is available to buy online through
              key retailers. Please click on the where to buy link on each
              product page.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain perfume?",
        faqAnswer: (
          <>
            <p>No, only flavourings.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain any parabens?",
        faqAnswer: (
          <>
            <p>No.</p>
          </>
        ),
      },
    ],
  },
  {
    category: "Ultra Lip Balm",
    faqs: [
      {
        uuid: v4(),
        faqTitle: "Does Ultra Lip Balm contain a sun protection factor?",
        faqAnswer: (
          <>
            <p>
              Yes, Ultra contains SPF 50 providing advanced sun protection to
              help defend lips from UVA and UVB rays.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "The packaging for Ultra Lip Balm has changed - is the formula still the same?",
        faqAnswer: (
          <>
            <p>
              Yes, although the packaging has been updated, the same trusted
              formulas remain.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "Does this product contain any ingredients derived from animals?",
        faqAnswer: (
          <>
            <p>No.</p>
          </>
        ),
      },

      {
        uuid: v4(),
        faqTitle: "Do you sell this product online?",
        faqAnswer: (
          <>
            <p>
              Ultra Lip Balm is available to buy online through key retailers.
              Please click on the where to buy link on each product page.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain perfume?",
        faqAnswer: (
          <>
            <p>No, only flavourings.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain any parabens?",
        faqAnswer: (
          <>
            <p>No.</p>
          </>
        ),
      },
    ],
  },
  {
    category: "MedPlus Jar",
    faqs: [
      {
        uuid: v4(),
        faqTitle:
          "Does this product contain any ingredients derived from animals?",
        faqAnswer: (
          <>
            <p>Yes, Beeswax and Lanolin</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Do you sell this product online? ",
        faqAnswer: (
          <>
            <p>
              MedPlus Jar is available to buy online through key retailers. Please
              click on the where to buy link on each product page.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain perfume?",
        faqAnswer: (
          <>
            <p>No, only flavourings.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain any parabens?",
        faqAnswer: (
          <>
            <p>No.</p>
          </>
        ),
      },
    ],
  },
  {
    category: "MedPlus Stick",
    faqs: [
      {
        uuid: v4(),
        faqTitle:
          "Does this product contain any ingredients derived from animals?",
        faqAnswer: (
          <>
            <p>Yes, Beeswax and Lanolin</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Do you sell this product online? ",
        faqAnswer: (
          <>
            <p>
              MedPlus Jar is available to buy online through key retailers. Please
              click on the where to buy link on each product page.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain perfume?",
        faqAnswer: (
          <>
            <p>No, only flavourings.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Does this product contain any parabens?",
        faqAnswer: (
          <>
            <p>No.</p>
          </>
        ),
      },
    ],
  },
  {
    category: "Lip Infusions Restore",
    faqs: [
      {
        uuid: v4(),
        faqTitle:
          "Does Lip Infusions Hydration contain a sun protection factor?",
        faqAnswer: (
          <>
            <p>No.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "Does this product contain any ingredients derived from animals?",
        faqAnswer: (
          <>
            <p>Yes, beeswax.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Do you sell this product online?",
        faqAnswer: (
          <>
            <p>
              Lip Infusions Restore is available to buy online through key
              retailers. Please click on the where to buy link on each product
              page.
            </p>
          </>
        ),
      },
    ],
  },
  {
    category: "Lip Infusions Hydration",
    faqs: [
      {
        uuid: v4(),
        faqTitle:
          "Does this product contain any ingredients derived from animals?",
        faqAnswer: (
          <>
            <p>Yes, beeswax.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Do you sell this product online?",
        faqAnswer: (
          <>
            <p>
              Lip Infusions Hydration is available to buy online through key
              retailers. Please click on the where to buy link on each product
              page.
            </p>
          </>
        ),
      },
    ],
  },
  {
    category: "Lip Infusions Soothing",
    faqs: [
      {
        uuid: v4(),
        faqTitle:
          "Does Lip Infusions Soothing contain a sun protection factor?",
        faqAnswer: (
          <>
            <p>No.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "Does this product contain any ingredients derived from animals?",
        faqAnswer: (
          <>
            <p>Yes, beeswax.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Do you sell this product online?",
        faqAnswer: (
          <>
            <p>
              Lip Infusions Soothing is available to buy online through key
              retailers. Please click on the where to buy link on each product
              page.
            </p>
          </>
        ),
      },
    ],
  },
  {
    category: "Lip Infusions Nourish",
    faqs: [
      {
        uuid: v4(),
        faqTitle: "Does Lip Infusions Nourish contain a sun protection factor?",
        faqAnswer: (
          <>
            <p>Yes SPF15.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "Does this product contain any ingredients derived from animals?",
        faqAnswer: (
          <>
            <p>Yes, beeswax.</p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Do you sell this product online?",
        faqAnswer: (
          <>
            <p>
              Lip Infusions Nourish is available to buy online through key
              retailers. Please click on the where to buy link on each product
              page.
            </p>
          </>
        ),
      },
    ],
  },

  {
    category: "General FAQS",
    faqs: [
      {
        uuid: v4(),
        faqTitle: "Can I use Blistex products under a lip stick or lip gloss?",
        faqAnswer: (
          <>
            <p>
              Use <a href="/medplus/">MedPlus Jar</a> or{" "}
              <a href="/ultra-lip-balm/">Ultra Lip Balm</a> before applying a
              lipstick or lip gloss to ensure the colour is applied more evenly,
              for a smoother look.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "What makes lip care different from skin care?",
        faqAnswer: (
          <>
            <p>
              Lip Skin does not have the same protective outer layer found in
              other type of skin. Unlike ordinary skin, lip tissue is mucous
              membrane. The outer layer of the lip (or stratum corneum) is
              extremely thin or even completely absent in most people. As a
              result, moisture evaporates from the lips much faster than from
              ordinary skin causing lips to dry out easily. This dryness
              frequently results in chapping and cracking, and increases the
              risk of inflammation, infection and burning sensations.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "How do I protect my lips from the sun?",
        faqAnswer: (
          <>
            <p>
              Lips have little or no melanin, the natural skin pigment that
              screens out the sun's harmful ultraviolet rays. Sun damage can
              alter collagen (which gives lips body and resilience) and can even
              lead to melanoma. Lips should be protected from the sun with a
              product that contains a Sun Protection Factor (SPF) of 15 or more.
              It's also important to remember that the sun's harmful rays are
              always present whether it's July or January, sunny or cloudy.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Do lips need to be protected indoors?",
        faqAnswer: (
          <>
            <p>
              It's a good idea to work lip protection into your daily skin care
              regime whether you spend time outdoors or not. Outdoor factors
              such as sun, wind and cold can speed up the rate of lip moisture
              loss. What you may not realize is that lips frequently suffer
              indoors from a lack of moisture in centrally heated or
              air-conditioned rooms in which the relative humidity is low.
              Dryness is also the enemy when a winter cold or flu strikes. Lip
              irritation can even be caused by certain types of food, cosmetics
              and cigarette smoke.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle:
          "Is it possible to reduce the effects of overexposure and aging on the lips?",
        faqAnswer: (
          <>
            <p>
              Using a lip care product with a sunscreen and effective
              moisturisers can help reduce the effects of two of the main
              contributors to an aged appearance; sun damage and moisture loss.
              Blistex combine proven moisturizers with sun protection ranging
              from SPF 10 to SPF 50.
            </p>
          </>
        ),
      },
      {
        uuid: v4(),
        faqTitle: "Should lip balm be worn under or over lipstick?",
        faqAnswer: (
          <>
            <p>
              Lip balm works best when worn under lipstick, which allows
              protective ingredients to be in direct contact with the lips.
              Using a moisturising lip care product before lipstick application
              will help color go on more evenly.
            </p>
          </>
        ),
      },
    ],
  },
]

class Faqs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: false,
      selectedCategory: data[0].category,
      faqs: [],
      fullSetFaqs: [],
      preExpanded: `${data[0].faqs[0].uuid}`,
      selectedIndex: 0,
    }
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleAccordionState = this.handleAccordionState.bind(this)
    this.filterList = this.filterList.bind(this)
  }

  handleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < 700 })
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize)
    this.handleWindowResize()
    const dataCopy = [...data]
    this.setState({
      faqs: dataCopy.filter(
        data => data.category === this.state.selectedCategory
      ),
      fullSetFaqs: dataCopy.map(item => item.faqs).flat(),
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize)
  }

  handleCategoryChange(category) {
    this.setState({
      selectedCategory: category,
      faqs: data.filter(data => data.category === category),
    })
  }

  handleAccordionState(accordionClassName) {
    const previousOpenChevrons = document.querySelectorAll(
      ".faqs__chevron--open"
    )
    previousOpenChevrons.forEach(chevron => {
      chevron.classList.remove("faqs__chevron--open")
    })
    const activeAccordion = document.getElementById(
      `accordion__heading-${accordionClassName}`
    )
    if (activeAccordion) {
      activeAccordion
        .querySelector(".faqs__chevron")
        .classList.add("faqs__chevron--open")
    }
  }

  filterList(e) {
    /* If search result is empty, return to the first category */
    if (e.target.value === "") {
      this.handleCategoryChange(data[0].category)
      return
    }

    /* Get updated list of the full set of questions and answers of FAQs */
    let updatedList = this.state.fullSetFaqs

    /* Take search term and get keywords, removing stop words */
    const keywords = e.target.value
      .toLowerCase()
      .removeStopWords()
      .split(" ")

    /* If the keywords are empty, return and don't searcj */
    if (
      keywords.length === 0 ||
      (keywords.length === 1 && keywords[0] === "")
    ) {
      return
    }

    /* Filter and reduce array based on keywords  */
    let newKeywordList = updatedList.filter(function(item) {
      return keywords.reduce(function(tillNow, now) {
        /* Check to see if there is more than one paragraph to check in the answer */
        if (!item.faqAnswer.props) {
          return
        }
        let answer = item.faqAnswer.props.children
        let updatedAnswer = Array.isArray(answer)
          ? answer
              .map(child => {
                return child.props.children ? String(child.props.children) : ""
              })
              .join(" ")
              .replace(".", "")
              .toLowerCase()
          : String(answer.props.children)
              .replace(".", "")
              .toLowerCase()

        let answerMatch = updatedAnswer.indexOf(now) !== -1

        return (
          tillNow + (item.faqTitle.replace(".", "").indexOf(now) !== -1) ||
          tillNow + answerMatch
        )
      }, 0)
    })

    /* Sort array into order of most matched keywords */

    function getMatchCountMap(data, criterias) {
      return data.reduce((countMap, curItem) => {
        if (!curItem.faqAnswer.props) {
          return
        }
        let item = Array.isArray(curItem.faqAnswer.props.children)
          ? curItem.faqAnswer.props.children
              .map(child => child.props.children)
              .join(" ")
          : curItem.faqAnswer.props.children.props.children
        let answer = String(item).replace(".", "")
        try {
          var count = criterias.filter(criteria =>
            new RegExp(criteria, "i").test(curItem.faqTitle + answer)
          ).length
        } catch (e) {
          console.log("error is: ", e)
        }

        countMap.push({
          value: curItem,
          count: count,
        })
        return countMap
      }, [])
    }

    function sortBasedOnCount(data, criterias) {
      var map = getMatchCountMap(data, criterias)
      map.sort((a, b) => b.count - a.count)
      return map.map(x => x.value)
    }

    if (newKeywordList.length === 0) {
      return
    }

    const searchResults = new Set(sortBasedOnCount(newKeywordList, keywords))
    const newList = { category: "", faqs: searchResults }
    this.setState({ faqs: [newList], selectedCategory: "" })
    const button = document.querySelector(
      ".accordion > .accordion__item .accordion__button"
    )

    if (
      button &&
      button.hasAttribute("aria-expanded") &&
      button.getAttribute("aria-expanded") === "false"
    ) {
      document.querySelector(".accordion__button").click()
    }
  }

  render() {
    const MobileCategorySelect = data.map((x, index) => (
      <option key={`mobile-cat-${index}`} value={x.category}>
        {x.category}
      </option>
    ))

    const CategoryLinks = data.map((x, index) => (
      <div key={`cat-${index}`}>
        <div
          className={
            "faqs__category-links" +
            (this.state.selectedCategory === x.category ? " active" : "")
          }
          onClick={e => this.handleCategoryChange(e.target.innerHTML)}
        >
          {x.category}
        </div>
      </div>
    ))

    const FAQS = this.state.faqs[0] ? [...this.state.faqs[0].faqs] : []

    return (
      <Layout>
        <SEO title="FAQ" />
        <section className="faqs">
          <h1>frequently asked questions</h1>
          <div className="two-col">
            <aside className="faqs__aside">
              <input
                className="faqs__search"
                type="search"
                id="category-search"
                name="category"
                placeholder="search for Frequently Asked Questions."
                aria-label="Search through site content"
                onChange={this.filterList}
              ></input>
              <div className="faqs__categories">
                {this.state.isMobile && (
                  <select
                    onChange={e => this.handleCategoryChange(e.target.value)}
                  >
                    {MobileCategorySelect}
                  </select>
                )}
                {!this.state.isMobile && CategoryLinks}
              </div>
            </aside>
            <div className="faqs__accordion">
              {this.state.faqs[0] && (
                <Accordion
                  className="accordion"
                  selectedIndex={this.state.selectedIndex}
                  onChange={(index, expanded, selectedIndex) =>
                    this.setState({ selectedIndex: selectedIndex })
                  }
                >
                  {FAQS.map((faq, index) => (
                    <div data-header={faq.faqTitle} key={index}>
                      <div className="accordion__inner-content">
                        <div>{faq.faqAnswer}</div>
                      </div>
                    </div>
                  ))}
                </Accordion>
              )}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default Faqs
